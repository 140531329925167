import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { useRouter } from "next/router";

type NavbarLinkProps = React.ComponentProps<"a">;

export const NavbarLink = ({ children, href }: NavbarLinkProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);
  const router = useRouter();

  return (
    <Link
      href={href}
      style={{
        color:
          router.pathname === href
            ? colors.surface.onSurface
            : colors.surface.onSurfaceVariant,
        fontWeight: router.pathname === href ? 600 : 500,
      }}
    >
      {children}
    </Link>
  );
};
