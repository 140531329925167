import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { SearchMobileModal } from "../Search/SearchMobileModal";
import { AppBar } from "@mui/material";

type SearchBarMobileWrapperProps = React.ComponentProps<"div">;

export const SearchBarMobileWrapper = ({
  children,
  className,
  ...props
}: SearchBarMobileWrapperProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const showMobileModal = useSelector(
    (state: RootState) => state.search.showMobileModal
  );
  const [showSearchBar, setShowSearchBar] = useState(true);

  useEffect(() => {
    let oldScrollY = window.scrollY;

    window.addEventListener("scroll", () => {
      if (oldScrollY < window.scrollY && window.scrollY > 100) {
        setShowSearchBar(false);
      } else {
        setShowSearchBar(true);
      }
      oldScrollY = window.scrollY;
    });
  }, []);

  return (
    <>
      {showMobileModal && <SearchMobileModal />}
      <div
        className={`justify-between w-screen flex mx-4 ${className}`}
        {...props}
      >
        <AppBar
          sx={{
            backgroundColor: componentColors.surface.surfContainerLowest,
            color: componentColors.surface.onSurfaceVariant,
            backgroundImage: "none",
          }}
          className={`h-[56px] rounded-[28px] px-[16px] justify-center w-[95%] ${
            showSearchBar ? "top-[16px]" : "-top-[100px]"
          } transition-all duration-300 left-1/2 transform -translate-x-1/2 z-40`}
        >
          <div className="flex items-center justify-center">{children}</div>
        </AppBar>
      </div>
    </>
  );
};
