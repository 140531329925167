import { Stack, Typography } from "@mui/material";
import { Pod1umLogo } from "../../../logo/Pod1umLogo";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { MaterialFooterSocials } from "./MaterialFooterSocials";
import { MaterialFooterGetTheApp } from "./MaterialFooterGetTheApp";
import { MaterialFooterHelpLinks } from "./MaterialFooterHelpLinks";
import Link from "next/link";

export const MaterialFooter = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack className="flex flex-col  pt-[120px] justify-between lg:container lg:mx-auto space-y-[100px] mt-auto ">
      <Stack className="flex lg:flex-row space-y-[20px] ml-4 lg:ml-0">
        <Stack className="lg:px-[16px] space-y-[24px] lg:w-1/3">
          <Stack className="w-[80px] h-[95px]">
            <Pod1umLogo />
          </Stack>

          <Typography
            sx={{
              color: theme.componentColors.surface.onSurface,
            }}
          >
            Experience the POD1UM difference, try a free workout today. Your
            journey to peak performance starts here.
          </Typography>
          <MaterialFooterSocials />
        </Stack>

        <Stack className="flex lg:flex-row lg:w-2/3 space-y-[10px] lg:space-y-0 justify-around">
          <MaterialFooterHelpLinks
            title="Coaches"
            links={[
              {
                title: "Apply",
                link: "mailto:support@pod1um.com?subject=Coach Application",
              },
            ]}
          />

          <MaterialFooterHelpLinks
            title="Athletes"
            links={[{ title: "Pricing", link: "/payment" }]}
          />

          <MaterialFooterHelpLinks
            title="Support"
            links={[
              {
                title: "Get Support",
                link: "mailto:support@pod1um.com?subject=Athlete Support",
              },
            ]}
          />
          <MaterialFooterGetTheApp />
        </Stack>
      </Stack>

      <Stack
        sx={{
          borderTop: "1px solid",
          borderTopColor: theme.componentColors.surface.onSurface,
        }}
        className="flex flex-col lg:flex-row justify-between items-center py-[20px]"
      >
        <Typography className="text-[18px] font-light ">
          Copyright &#169; 2024 Pod1um
        </Typography>

        <Stack direction={"row"} className="items-center space-x-2 px-4">
          <Typography className="text-[18px] font-light">
            All rights Reserved
          </Typography>
          <Typography> | </Typography>
          <Link href="https://join.pod1um.com/terms/">
            <Typography
              className="text-[18px] font-light"
              sx={{
                color: theme.componentColors.primary.primary,
              }}
            >
              Terms and Conditions
            </Typography>
          </Link>
          <Typography> | </Typography>
          <Link href="https://join.pod1um.com/terms/">
            <Typography
              sx={{
                color: theme.componentColors.primary.primary,
              }}
              className="text-[18px] font-light"
            >
              {" "}
              Privacy Policy
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
