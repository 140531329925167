import { IconButton, InputBase, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../buttons/material/primaryButton/PrimaryButton";
import Link from "next/link";
import { userLogin } from "../../../../lib/authentication";
import { useRouter } from "next/router";
import {
  setErrorMessages,
  setIsLoadingForm,
  setIsLoadingPage,
  setLoginModal,
} from "../../../../redux/pages/loginPageSlice";
import { LoginFormLoadingServiceBtns } from "./LoginFormLoadingServiceBtns";
import { useLoggedUser } from "../../../../hooks/user/useLoggedUser";
import { LoginFormServiceBtns } from "./LoginFormServiceBtns";
import { AuthForgotPasswordButton } from "../authForgotPasswordButton/AuthForgotPasswordButton";
import { ILoginForm } from "../../../../interfaces/globalComponents/authComponents/login/ILoginForm";
import { setRegisterModal } from "../../../../redux/pages/registerPageSlice";

export const LoginForm = ({ isModal = false }: ILoginForm) => {
  const theme = useSelector((state: RootState) => state.theme);
  const [showPassword, setShowPassword] = useState(false);
  const [loginInputs, setLoginInputs] = useState({ email: "", password: "" });
  const router = useRouter();
  const loginPageRedux = useSelector((state: RootState) => state.loginPage);
  const dispatch = useDispatch();
  const isLoggedInUser = useSelector(
    (state: RootState) => state.user.value.loggedUser
  );
  const isLoadingForm = useSelector(
    (state: RootState) => state.loginPage.isLoadingForm
  );

  useLoggedUser("/profile");

  useEffect(() => {
    if (!isLoggedInUser && isLoggedInUser !== null) {
      dispatch(setIsLoadingPage(false));
    }
  }, [isLoggedInUser, router, dispatch]);

  const handleSubmit = () => {
    if (loginInputs.email.trim().length === 0) {
      dispatch(
        setErrorMessages({
          emailErrorMessage: "Enter a valid email",
        })
      );
      return;
    }

    if (loginInputs.password.trim().length < 6) {
      dispatch(
        setErrorMessages({
          passwordErrorMessage: "Enter a longer password",
        })
      );
      return;
    }

    dispatch(setIsLoadingForm(true));

    userLogin(loginInputs.email, loginInputs.password)
      .then((res) => {
        if (res.token && isModal) {
          void router.reload();
        }

        if (res.token && !isModal) {
          void router.push("/");
        }

        isModal && dispatch(setLoginModal());
      })
      .catch((error: Error) => {
        dispatch(
          setErrorMessages({
            formErrorMessage: error.message,
          })
        );

        if (error.message === "admin") {
          return;
        }
      })
      .finally(() => {
        dispatch(setIsLoadingForm(false));
      });
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.componentColors.surface.surfcontainerLow,
      }}
      className="p-[46px] flex flex-col items-center justify-center rounded-[12px] "
    >
      <Stack className="lg:w-[445px] w-full">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack className="space-y-[16px] w-full">
            <Typography
              className="text-[22px] font-medium lg:font-semibold first-letter:text-left"
              sx={{ color: theme.componentColors.surface.onSurfaceDetachable }}
            >
              Log in to POD1UM
            </Typography>
            <Stack>
              <InputBase
                disabled={loginPageRedux.isLoadingForm}
                type="email"
                onChange={(event) => {
                  dispatch(setErrorMessages({ emailErrorMessage: "" }));
                  setLoginInputs({ ...loginInputs, email: event.target.value });
                }}
                className="py-[12px] px-[16px] w-full rounded-[8px] h-[44px] text-[14px]"
                sx={{
                  backgroundColor:
                    theme.componentColors.surface
                      .surfaceContainerHightVariation,
                }}
                placeholder="E-mail"
              />
              <Typography
                sx={{
                  color: theme.componentColors.error.error,
                }}
                className="text-[12px]"
              >
                {loginPageRedux.errorMessages.emailErrorMessage}
              </Typography>
            </Stack>
            <Stack>
              <Stack
                sx={{
                  backgroundColor:
                    theme.componentColors.surface
                      .surfaceContainerHightVariation,
                }}
                className=" h-[44px] px-[16px] rounded-[8px] items-center justify-center flex"
                direction={"row"}
              >
                <InputBase
                  disabled={loginPageRedux.isLoadingForm}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="w-full text-[14px]"
                  onChange={(event) => {
                    setLoginInputs({
                      ...loginInputs,
                      password: event.target.value,
                    });

                    dispatch(
                      setErrorMessages({
                        passwordErrorMessage: "",
                      })
                    );
                  }}
                />
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? (
                    <VisibilityOffIcon
                      className="text-[20px]"
                      sx={{
                        color: theme.componentColors.primary.primary,
                      }}
                    />
                  ) : (
                    <VisibilityIcon
                      className="text-[20px]"
                      sx={{
                        color: theme.componentColors.primary.primary,
                      }}
                    />
                  )}
                </IconButton>
              </Stack>
              <Typography
                sx={{
                  color: theme.componentColors.error.error,
                }}
                className="text-[12px]"
              >
                {loginPageRedux.errorMessages.passwordErrorMessage}
              </Typography>
            </Stack>

            {loginPageRedux.isLoadingPage ? (
              <div
                style={{
                  backgroundColor:
                    theme.componentColors.surface.surfContainerHighest,
                }}
                className="w-full rounded-2xl h-[40px] animate-pulse"
              />
            ) : (
              <PrimaryButton
                disabled={loginPageRedux.isLoadingForm}
                title="Login"
                type="submit"
              />
            )}
            <Typography
              sx={{
                color: theme.componentColors.error.error,
              }}
              className="text-[14px] text-center"
            >
              {loginPageRedux.errorMessages.formErrorMessage}
            </Typography>
          </Stack>
        </form>

        <Stack className="w-full mt-[5px]">
          {loginPageRedux.isLoadingPage ? (
            <div />
          ) : (
            <AuthForgotPasswordButton />
          )}
        </Stack>
        <Stack className="w-full my-[66px] space-y-[16px]">
          <div className="flex items-center justify-between w-full space-x-[2px]">
            <div
              style={{
                backgroundColor: theme.componentColors.primary.primary,
              }}
              className="w-[20%] sm:w-[35%] h-[0.5px]"
            />
            <span className="text-[14px]">Or sign in with</span>
            <div
              style={{
                backgroundColor: theme.componentColors.primary.primary,
              }}
              className="w-[20%] sm:w-[35%] h-[0.5px]"
            />
          </div>
          <div>
            {loginPageRedux.isLoadingPage ? (
              <LoginFormLoadingServiceBtns />
            ) : (
              <LoginFormServiceBtns
                isLoadingForm={isLoadingForm}
                toReload={false}
              />
            )}
          </div>
        </Stack>
      </Stack>
      <Typography
        sx={{
          color: theme.componentColors.surface.surfaceFull,
        }}
        className="text-[14px] font-light"
      >
        Don&apos;t have an account?{" "}
        {isModal ? (
          <span
            style={{
              color: theme.componentColors.primary.primary,
            }}
            className="cursor-pointer"
            onClick={() => {
              dispatch(setLoginModal());
              dispatch(setRegisterModal());
            }}
          >
            Sign up
          </span>
        ) : (
          <Link
            style={{
              color: theme.componentColors.primary.primary,
            }}
            href="/create-account"
            className="font-light text-[14px]"
          >
            Sign up
          </Link>
        )}
      </Typography>
    </Stack>
  );
};
