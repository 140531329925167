import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Stack } from "@mui/material";

type NavbarWrapperProps = React.ComponentProps<"div">;

export const NavbarWrapper = ({ children }: NavbarWrapperProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const userRedux = useSelector((state: RootState) => state.user.value);

  return (
    <Stack
      sx={{
        backgroundColor: componentColors.surface.surfContainer,
      }}
      direction={"row"}
      className={` hidden lg:flex items-center justify-between lg:py-[14px] fixed top-0 right-0 h-[55px] z-40  ${
        userRedux.loggedUser && userRedux.rendered
          ? "w-[calc(100%-6.2rem)]"
          : "w-full"
      }`}
    >
      <Stack
        direction={"row"}
        className="flex items-center justify-between w-full px-5"
      >
        {children}
      </Stack>
    </Stack>
  );
};
