import React from "react";
import { NavbarLink } from "./NavbarLink";

export const NavbarLinks = () => {
  return (
    <div className="flex space-x-6">
      <NavbarLink href="/">For athletes</NavbarLink>
      <NavbarLink href="/coach">For coaches</NavbarLink>
      <NavbarLink href="/payment">Pricing</NavbarLink>
    </div>
  );
};
