import React from "react";
import { NavbarLogo } from "./NavbarLogo";
import { NavbarWrapper } from "./NavbarWrapper";
import { SearchBarInput } from "../Search/SearchBarInput";
import { useSelector } from "react-redux";
import { ProfileAvatar } from "../avatar/ProfileAvatar";
import { RootState } from "../../../../../redux/store";
import { NavbarLoginButtons } from "./NavbarLoginButtons";
import { NavbarLinks } from "./NavbarLinks/NavbarLinks";

export const Navbar = () => {
  const userRedux = useSelector((state: RootState) => state.user.value);

  return (
    <NavbarWrapper>
      <NavbarLogo />

      {userRedux.loggedUser ? <SearchBarInput /> : <NavbarLinks />}

      {userRedux.loggedUser ? <ProfileAvatar /> : <NavbarLoginButtons />}
    </NavbarWrapper>
  );
};
