import { Stack } from "@mui/material";
import Link from "next/link";
import { TextButton } from "../../../buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../buttons/material/primaryButton/PrimaryButton";

export const NavbarLoginButtons = () => {
  return (
    <Stack direction="row" className="flex items-center justify-center">
      <Link href="/login">
        <TextButton text="Log In" />
      </Link>

      <Link href="/create-account">
        <PrimaryButton title="Get Started" />
      </Link>
    </Stack>
  );
};
