import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const Pod1umLogo = () => {
  const theme = useSelector((state: RootState) => state.theme);

  if (theme.mode === "dark") {
    return (
      <img
        alt="Pod1um Full Logo"
        title="Pod1um Full Logo"
        className="w-full h-full object-contain"
        src={`/images/pod1um-white-logo.png`}
      />
    );
  }

  return (
    <img
      alt="Pod1um Full Logo"
      title="Pod1um Full Logo"
      className="w-full h-full object-contain"
      src={`/images/pod1um-black-logo.png`}
    />
  );
};
