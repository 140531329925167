import { Stack, Typography } from "@mui/material";
import React from "react";
import MobileStores from "../../../../homepage/MobileStores/MobileStores";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const MaterialFooterGetTheApp = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack className="flex flex-col items-center  space-y-5 lg:space-y-10">
      <Typography
        className="text-[20px] font-medium"
        sx={{
          color: theme.componentColors.surface.onSurfaceDetachable,
        }}
      >
        Get the app
      </Typography>
      <MobileStores />
    </Stack>
  );
};
