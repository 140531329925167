import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { RegisterForm } from "../../../auth/register/RegisterForm";
import { DefaultModal } from "../DefaultModal";
import { setRegisterModal } from "../../../../../redux/pages/registerPageSlice";

export const RegisterModal = () => {
  const showRegisterModal = useSelector(
    (state: RootState) => state.registerPage.showRegisterModal
  );
  const dispatch = useDispatch();

  return (
    <DefaultModal
      open={showRegisterModal}
      onClose={() => {
        dispatch(setRegisterModal());
      }}
      showBottomsheetOnlyForMobile={true}
    >
      <div className="md:w-[512px]">
        <RegisterForm isModal={true} />
      </div>
    </DefaultModal>
  );
};
