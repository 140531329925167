import Link from "next/link";
import React from "react";
import { Pod1umSvgIcon } from "../../../svg/Pod1umSvgIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const NavbarLogo = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <Link href="/">
      <Pod1umSvgIcon color={componentColors.surface.onSurface} />
    </Link>
  );
};
